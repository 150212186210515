import React, { useState } from "react"
import { Masonry } from "masonic"
import { useWindowSize } from "@react-hook/window-size"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styles from "./index.module.css"
import AppleLogoIcon from "../components/svg/appleLogoIcon"
import DesktopIcon from "../components/svg/desktopIcon"

import masonryMainImage1 from "../images/temp/masonryMainImages/image 111.jpg"
import masonryMainImage2 from "../images/temp/masonryMainImages/image 112.jpg"
import masonryMainImage3 from "../images/temp/masonryMainImages/image 115.jpg"
import masonryMainImage4 from "../images/temp/masonryMainImages/image 118.jpg"
import masonryMainImage5 from "../images/temp/masonryMainImages/image 119.jpg"
import masonryMainImage6 from "../images/temp/masonryMainImages/image 120.jpg"
import masonryMainImage7 from "../images/temp/masonryMainImages/image 122.jpg"
import masonryMainImage8 from "../images/temp/masonryMainImages/image 123.jpg"
import masonryMainImage9 from "../images/temp/masonryMainImages/image 124.jpg"

import masonryAuthorImage1 from "../images/temp/masonryAuthorImages/image 113.jpg"
import masonryAuthorImage2 from "../images/temp/masonryAuthorImages/image 114.jpg"
import masonryAuthorImage3 from "../images/temp/masonryAuthorImages/image 116.jpg"
import masonryAuthorImage4 from "../images/temp/masonryAuthorImages/image 117.jpg"
import MobileFrame from "../components/svg/mobileFrame"
import BrowserScreenWithIPhone from "../components/svg/browserScreenWithIPhone"

const fakeMasonryCards = [
  {
    id: 123,
    mainImage: masonryMainImage1,
    authorImage: masonryAuthorImage1,
    authorName: "Estúdio Gole",
    authorTag: "@estudiogole",
  },
  {
    id: 1234,
    mainImage: masonryMainImage2,
    authorImage: masonryAuthorImage2,
    authorName: "Rron Berisha",
    authorTag: "@rronberisha",
  },
  {
    id: 1235,
    mainImage: masonryMainImage3,
    authorImage: masonryAuthorImage3,
    authorName: "Wayne Season",
    authorTag: "@wayne_season",
  },
  {
    id: 1236,
    mainImage: masonryMainImage4,
    authorImage: masonryAuthorImage4,
    authorName: "F. JAY SCOTT",
    authorTag: "@fraserscott2",
  },
  {
    id: 1237,
    mainImage: masonryMainImage5,
    authorImage: masonryAuthorImage3,
    authorName: "Wayne Season",
    authorTag: "@wayne_season",
  },
  {
    id: 1238,
    mainImage: masonryMainImage6,
    authorImage: masonryAuthorImage2,
    authorName: "Rron Berisha",
    authorTag: "@rronberisha",
  },
  {
    id: 1239,
    mainImage: masonryMainImage7,
    authorImage: masonryAuthorImage1,
    authorName: "Estúdio Gole",
    authorTag: "@estudiogole",
  },
  {
    id: 12310,
    mainImage: masonryMainImage8,
    authorImage: masonryAuthorImage2,
    authorName: "Rron Berisha",
    authorTag: "@rronberisha",
  },
  {
    id: 12311,
    mainImage: masonryMainImage9,
    authorImage: masonryAuthorImage3,
    authorName: "Wayne Season",
    authorTag: "@wayne_season",
  },
]

const MasonryCard = ({
  data: { mainImage, authorImage, authorName, authorTag },
}) => (
  <div className={styles.masonryCard}>
    <img
      src={mainImage}
      alt={`original content by ${authorName} (${authorTag})`}
      className={styles.mainCardImage}
    />
    <div className={styles.description}>
      <img
        src={authorImage}
        className={styles.authorImage}
        alt={`${authorName} (${authorTag})`}
      />{" "}
      <span className={styles.authorName}>{authorName}</span>{" "}
      <span className={styles.authorTag}>{authorTag}</span>
    </div>
  </div>
)

const IndexPage = ({ data }) => {
  const [updateCollection, setUpdateCollection] = useState(false)
  const [windowWidth, windowHeight] = useWindowSize()
  return (
    <Layout pageWrapperClassName={styles.layout}>
      <SEO title="Home" />
      <div className={styles.pageWrapper}>
        <div className={styles.section}>
          <h2>
            workspace for visual artists
            <br />
            enhanced by{" "}
            <span className={styles.alternative}>AI algorithms</span>
          </h2>
          <p className={styles.large}>
            Collect references, upload your assets,
            <br />
            and organize personal libraries.
            <br />
            Explore inspiring content using <br />
            artificial intelligence.
          </p>
          <div className={styles.buttonsGroup}>
            <a
              href="https://apps.apple.com/us/app/graphica-ai/id1541725787"
              target="_blank"
              rel="noreferrer"
            >
              <button className={`${styles.button} ${styles.black}`}>
                <AppleLogoIcon
                  className={`${styles.platformLogo} ${styles.apple}`}
                />{" "}
                download iOS app
              </button>
            </a>
            <a href="https://app.graphica.ai/">
              <button className={`${styles.button} ${styles.red}`}>
                <DesktopIcon
                  className={`${styles.platformLogo} ${styles.desktop}`}
                />{" "}
                open web beta
              </button>
            </a>
          </div>
        </div>
        <div className={`${styles.section} ${styles.second}`}>
          <h3>
            discover web-wide collection of the artwork auto-selected using{" "}
            <span>aesthetic analysis</span>
          </h3>
          <p>
            We apply computer vision algorithms to process thousands of images
            <br />
            from the most popular graphical sources and filter only the best
            results
            <br />
            for a custom-tailored inspiration.
          </p>
          <div className={styles.appFunctionalBlock}>
            <BrowserScreenWithIPhone
              browserImageURL="https://graphica.s3.eu-west-1.amazonaws.com/main/Menu.+Footer+Instagram.png"
              phoneContent={
                <img
                  src="https://graphica.s3.eu-west-1.amazonaws.com/main/graphica-ai-ios-1.png"
                  alt="iphone screenshot"
                />
              }
              className={styles.imageHolder}
            />
            <div className={styles.description}>
              <div className={styles.column}>
                <h4>Search</h4>
                <p>
                  Find exciting projects via tags
                  <br />
                  objects, styles, and colors
                  <br />
                  Browse ai-based suggestions
                  <br />
                  of similar designs
                </p>
                <h4>Organize</h4>
                <p>Create your personal libraries and organize images</p>
              </div>
              <div className={styles.column}>
              <h4>Collect</h4>
                <p>
                  Save images from any source and store them in the libraries
                </p>
                <h4>Promote</h4>
                <p>
                  Upload your projects to be featured on our feed to reach out
                  to new audience
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={`${styles.section} ${styles.third}`}>
          <h3>
            design inspiration
            <br />
            powered by <span>machine learning</span>
          </h3>
          <div className={styles.secondAppFunctionalBlock}>
            <div className={styles.phonesContainer}>
              <MobileFrame>
                <img
                  src="https://graphica.s3.eu-west-1.amazonaws.com/main/graphica-ai-ios-2.png"
                  alt="iphone screenshot"
                />
              </MobileFrame>
              <MobileFrame>
                <video
                  preload="auto"
                  autoPlay
                  loop="loop"
                  className={styles.video}
                  muted
                >
                  <source
                    src="https://graphica.s3.eu-west-1.amazonaws.com/main/RPReplay_Final1607807328.mp4"
                    type="video/mp4"
                  />
                </video>
              </MobileFrame>
            </div>
            <div className={styles.description}>
              <div className={styles.column}>
                <h4>Advanced search</h4>
                <p>
                  Type any keywords such as author, objects on the image, visual
                  style, or design category
                </p>
                <h4>Search by color</h4>
                <p>Select up to 5 colors and find images that fit them best</p>
              </div>
              <div className={styles.column}>
                <h4>Personalized feed</h4>
                <p>
                  Select areas of interest and receive content based on your
                  preferences
                  <br />
                  <br />
                  Continue browsing on our platform, and the system will
                  identify your interests and provide more personalized content
                </p>
              </div>
              <div className={`${styles.column} ${styles.w100}`}>
                <h4>Similar design suggestions</h4>
                <p>
                  For each image, get recommendations of similar artwork from
                  wide range of authors and design categories
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.section}>
          <h3>
            explore <span>cross-disciplinary</span> references
            <br />
            for teams and individuals
          </h3>
          <div className={styles.themesBlock}>
            <img
              src="https://graphica.s3.eu-west-1.amazonaws.com/main/peoplesInSpaceSuit.png"
              alt="peoples in space suit"
              title="peoples in space suit"
              className={styles.leftImageContainer}
            />
            <div className={styles.themes}>
              <div className={styles.column}>
                <h4>
                  <a href="https://app.graphica.ai/category/961f286a-93af-4271-8d80-aa2de55d242a">
                    Graphic Design
                  </a>
                </h4>
                <ul>
                  <li>
                    <a href="https://app.graphica.ai/category/c7a5f681-d3c7-4f3a-aca8-4c7e807f1703">
                      Branding
                    </a>
                  </li>
                  <li>
                    <a href="https://app.graphica.ai/category/b567d6c8-041a-4339-966e-21f002c63d7e">
                      Identity
                    </a>
                  </li>
                  <li>
                    <a href="https://app.graphica.ai/category/04951809-44be-4af8-9ff7-bd2af12299dd">
                      Packaging
                    </a>
                  </li>
                  <li>
                    <a href="https://app.graphica.ai/category/70dfde5e-b6d3-4bc4-aea5-9c6db63d9471">
                      Poster Design
                    </a>
                  </li>
                  <li>
                    <a href="https://app.graphica.ai/category/e830118d-fa86-4fb5-9d4b-596e036080b3">
                      Editorial
                    </a>
                  </li>
                  <li>
                    <a href="https://app.graphica.ai/category/dd29e6f9-eda7-43bd-a433-dac9f923ff2f">
                      Typography
                    </a>  
                  </li>
                  <li>
                    <a href="https://app.graphica.ai/category/f0208889-7272-4b36-81b6-32bd87665b33">
                      Logo Design
                    </a>  
                  </li>
                </ul>
                <h4 className={styles.shownS}>
                  <a href="https://app.graphica.ai/category/72c730a6-ba0b-4fc8-8a8c-2e2cffd92315">
                    UI/UX Design
                  </a>
                </h4>
                <ul className={styles.shownS}>
                  <li>
                    <a href="https://app.graphica.ai/category/01e4126f-c278-48ce-a754-17168fa6e0b4">
                      Mobile Design
                    </a>   
                  </li>
                  <li>
                    <a href="https://app.graphica.ai/category/ef557abc-16ab-4b06-8512-2cf46681f8ab">
                      Web Design
                    </a>   
                  </li>
                  <li>
                    <a href="https://app.graphica.ai/category/d67c26c2-fdb7-463f-bd91-aa914e3aa1c9">
                      Interaction Design
                    </a>   
                  </li>
                  <li>
                    <a href="https://app.graphica.ai/category/ef557abc-16ab-4b06-8512-2cf46681f8ab">
                      Landing Pages
                    </a>   
                  </li>
                  <li>
                    <a href="https://app.graphica.ai/category/7e9c6d9e-5a66-4a01-b15b-576fee03c027">
                      UI Patterns
                    </a>   
                  </li>
                  <li>
                    <a href="https://app.graphica.ai/category/f8109542-6509-432b-a88a-d7759b073860">
                      UI Controls
                    </a>   
                  </li>
                </ul>
              </div>
              <div className={`${styles.column} ${styles.hidableS}`}>
                <h4>
                  <a href="https://app.graphica.ai/category/72c730a6-ba0b-4fc8-8a8c-2e2cffd92315">
                    UI/UX Design
                  </a>
                </h4>
                <ul>
                <li>
                    <a href="https://app.graphica.ai/category/01e4126f-c278-48ce-a754-17168fa6e0b4">
                      Mobile Design
                    </a>   
                  </li>
                  <li>
                    <a href="https://app.graphica.ai/category/ef557abc-16ab-4b06-8512-2cf46681f8ab">
                      Web Design
                    </a>   
                  </li>
                  <li>
                    <a href="https://app.graphica.ai/category/d67c26c2-fdb7-463f-bd91-aa914e3aa1c9">
                      Interaction Design
                    </a>   
                  </li>
                  <li>
                    <a href="https://app.graphica.ai/category/ef557abc-16ab-4b06-8512-2cf46681f8ab">
                      Landing Pages
                    </a>   
                  </li>
                  <li>
                    <a href="https://app.graphica.ai/category/7e9c6d9e-5a66-4a01-b15b-576fee03c027">
                      UI Patterns
                    </a>   
                  </li>
                  <li>
                    <a href="https://app.graphica.ai/category/f8109542-6509-432b-a88a-d7759b073860">
                      UI Controls
                    </a>   
                  </li>
                </ul>
                <h4 className={styles.alternative}>
                  <a href="https://app.graphica.ai/category/cecc0a4a-f1b0-47ee-985e-2741d0c51ca9">
                    Industrial Design
                  </a>
                </h4>
                <h4 className={styles.alternative}>
                  <a href="https://app.graphica.ai/category/39277f74-bf6c-4d8b-a45b-3e05f01ab290">
                    Information Design
                  </a>
                </h4>
                <h4 className={styles.alternative}>
                  <a href="https://app.graphica.ai/category/f493daac-9b5b-48fb-99b8-56c2ece9c4ba">
                    Marketing
                  </a>
                </h4>
              </div>
              <div className={`${styles.column} ${styles.hidable}`}>
                <h4>
                  <a href="https://app.graphica.ai/category/cecc0a4a-f1b0-47ee-985e-2741d0c51ca9">
                    Industrial Design
                  </a>
                </h4>
                <h4>
                  <a href="https://app.graphica.ai/category/39277f74-bf6c-4d8b-a45b-3e05f01ab290">
                    Information Design
                  </a>
                </h4>
                <h4>
                  <a href="https://app.graphica.ai/category/f493daac-9b5b-48fb-99b8-56c2ece9c4ba">
                    Marketing
                  </a>
                </h4>
              </div>
              <div className={styles.column}>
                <h4 className={styles.alternativeS}>
                  <a href="https://app.graphica.ai/category/cecc0a4a-f1b0-47ee-985e-2741d0c51ca9">
                    Industrial Design
                  </a>
                </h4>
                <h4 className={styles.alternativeS}>
                  <a href="https://app.graphica.ai/category/39277f74-bf6c-4d8b-a45b-3e05f01ab290">
                    Information Design
                  </a>
                </h4>
                <h4 className={styles.alternativeS}>
                  <a href="https://app.graphica.ai/category/f493daac-9b5b-48fb-99b8-56c2ece9c4ba">
                    Marketing
                  </a>
                </h4>
                <h4>
                  <a href="https://app.graphica.ai/category/813905dc-9dfc-441e-a62c-ba7be50f20a4">
                    Architecture
                  </a>
                </h4>
                <h4>
                  <a href="https://app.graphica.ai/category/d3841502-e768-4d7e-95b6-8707439d1108">
                    Fashion
                  </a>
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div className={`${styles.section} ${styles.interactive}`}>
          <div className={styles.leftSide}>
            <h3>
              beautiful <br className={styles.xl} /> mood boards{" "}
              <br className={styles.m} /> created by{" "}
              <span>artificial intelligence</span>{" "}
            </h3>
            <p>
              We process images <br className={styles.xl} />
              and retrieve data about theme, objects, context, color, layout,
              composition, and other key features. This data is applied to the
              search process of similar images.
            </p>
            <button
              className={`${styles.button} ${styles.black}`}
              onClick={() => {
                setUpdateCollection(true)
                setTimeout(() => {
                  setUpdateCollection(false)
                }, 2000)
              }}
              disabled={updateCollection}
            >
              try it out
            </button>
          </div>
          <div className={styles.rightSide}>
            <div className={styles.masonryGrid}>
              <Masonry
                items={fakeMasonryCards}
                render={MasonryCard}
                columnCount={windowWidth > 960 ? 3 : windowWidth > 600 ? 2 : 1}
                columnGutter={16}
              />
              <div
                className={`${styles.loaderLayout} ${
                  !updateCollection ? styles.hide : ""
                }`}
              >
                <div className={styles.loaderRing}>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div className={styles.backgroundCircle}></div>
                </div>
                <div className={styles.description}>
                  <p>Creating new collection...</p>
                  <p className={styles.muted}>It shouldn't take too long</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
