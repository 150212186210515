import React from "react"
import PropTypes from "prop-types"

export default function DesktopIcon({ color, height, width, className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "38"}
      height={height || "29"}
      fill="none"
      viewBox="0 0 38 29"
      className={className}
    >
      <path
        fill={color || "#fff"}
        d="M38 23.282V0H0v23.282h18.667v3.654h-7.609V29h17.446v-2.064h-7.677v-3.654H38z"
      ></path>
    </svg>
  )
}

DesktopIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
